import type { PageProps } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import InstitutionalText from 'src/components/sections/InstitutionalText'
import Newsletter from 'src/components/sections/Newsletter'
import Promotions from 'src/components/common/Institutional/Promotions'

const Promocoes: FCC<PageProps> = (props) => {
  const {
    location: { host, pathname },
  } = props

  const title = 'Promoções | Miess'

  const docTitle = 'Promoções'

  const slug = 'institucionais/AntesCompra/promocoes'

  const description =
    'Encontre as melhores Lingeries e Produtos de Sex Shop Atacado e Varejo. Conheça a loja mais completa de produtos eróticos do Brasil.'

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />

      {/* Sections */}

      <Breadcrumb
        name={docTitle}
        breadcrumbList={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />
      <InstitutionalText
        headerImage={{
          desktop: {
            src: 'https://miess.vtexassets.com/arquivos/banner-compre-agora-desktop-2.jpg',
            width: 1920,
            height: 195,
          },
          mobile: {
            src: 'https://miess.vtexassets.com/arquivos/banner-compre-agora-mobile-2.jpg',
            width: 1280,
            height: 163,
          },
          href: '/sex-shop',
          alt: 'Aproveite as vantagens exclusivas. Compre agora!',
        }}
      >
        <Promotions />
      </InstitutionalText>
      <Newsletter />
    </>
  )
}

export default Promocoes
