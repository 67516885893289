import Card from '../Card'
import * as S from './styles'

const Promotions: FCC = () => {
  const cmsInfo = [
    {
      title:
        '<h3 class="active">Mimos p/ você 🎁 Compre mais e GANHE mais AINDA! ✨</h3>',
      content:
        '<p>• Em compras acima de $120 PAGOS, ganhe 1 Gel Deslizante (Sortido).</p>\n<p>• Em compras acima de $800 PAGOS, ganhe 1 Gel Deslizante (Sortido) e 1 Jogo de Tabuleiro Miess (Sortido).</p>\n\n<p>👉 Aproveite: <u><span style="background-color: #e2554f"><a href="https://www.miess.com.br/sex-shop" style="color: #ffffff">clique aqui</a></span></u></p>\n\n<p>Promoção única por CPF, válida para pedidos finalizados à partir do dia 06/07 até durarem os estoques. Brindes sujeitos a alteração sem aviso prévio devido ao saldo em estoque. Importante: Caso não escolha uma das opções de brinde, não será possível acrescentar posteriormente. Brindes disponíveis sob condição do valor mínimo em pedidos PAGOS após a aplicação do desconto.</p>',
    },
    {
      title: '<h3>No Pix você ganha   10% off! 🤑 </h3>',
      content:
        '<p>Ganhe mais 10% de desconto adicionais em suas compras, válido apenas para os pedidos finalizados na modalidade de pagamento PIX (desconto aplicado após selecionar a forma de pagamento).</p>\n<p>Promoção cumulativa, válida para pedidos finalizados no dia 02/08.</p>\n<p>Arrase nas comprinhas! 🛒 <u><span style="background-color: #e2554f"><a href="https://www.miess.com.br/sex-shop" style="color: #ffffff">clique aqui</a></span></u></p>',
    },
    {
      title: '<h3> Ei ✋ 65% em TODA a Loja... 🛒</h3>',
      content:
        '<p>Regulamento: Promoção não cumulativa, válida para pedidos finalizados no dia 02/08. Desconto de 65% sem mínimo de compras já aplicado em todo o site (valor em branco), sobre o valor *DE (valor à vista e a prazo).</p>\n<p>✨ Veja nossos conjuntinhos: <u><span style="background-color: #e2554f"><a href="https://www.miess.com.br/lingerie/conjunto?sort=release_desc" style="color: #ffffff">clique aqui</a></span></u></p>',
    },
    {
      title: '<h3>Eu ouvi Sedex Grátis? 🚚 </h3>',
      content:
        '<p>Sedex Grátis em compras acima de R$99,99 PAGOS para todas as regiões do Brasil, utilizando o cupom: SDEXX99</p>\n<p>Promoção válida apenas para pedidos finalizados no dia 02/08.</p>\n<p>💖 Novas lingeries p/ arrasar: <u><span style="background-color: #e2554f"><a href="https://www.miess.com.br/lingerie?sort=release_desc" style="color: #ffffff">clique aqui</a></span></u></p>',
    },
    {
      title: '<h3> Receba já, entrega EXPRESS! 🛵 </h3>',
      content:
        '<p> Motoboy a partir de R$12,90 em compras. O serviço de entrega express está disponível somente para algumas regiões de São Paulo. A entrega rápida está disponível somente de segunda-feira a sexta-feira. Não são feitas entregas aos sábados, domingos e feriados. Consulte sua faixa de CEP. </p>\n<p>🌟 Novidades quentíssimas: <u><span style="background-color: #e2554f"><a href="https://www.miess.com.br/sex-shop?sort=release_desc" style="color: #ffffff">clique aqui</a></span></u></p>',
    },
    {
      title: '<h3>Mimos Prudence tá on! 🎁 Se liga aqui...</h3>',
      content:
        '<p>Na compra de R$80 PAGOS em produtos da marca Prudence, leve para casa um mimo sortido.</p>\n<p>Promoção válida em compras a partir do dia 14/01 até durarem os estoques.</p>\n<p>🔥 Pegue já o seu: <u><span style="background-color: #e2554f"><a href="https://www.miess.com.br/prudence" style="color: #ffffff">clique aqui</a></span></u></p>',
    },
  ]

  return (
    <S.Container>
      <h1>
        SUA PROMO FAVORITA ESTÁ AQUI!{' '}
        <span role="img" aria-label="Coração">
          💖
        </span>
      </h1>
      <h2>PROMOÇÕES E REGULAMENTOS</h2>
      <div>
        {cmsInfo.map(({ title, content }, index) => (
          <Card key={index} title={title} content={content} />
        ))}
      </div>
    </S.Container>
  )
}

export default Promotions
